var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('Header',{attrs:{"title":_vm.$t('profile.friends.title')}}),(_vm.befriendUrl)?_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.$t('profile.friends.invite-friend')))]),_c('p',[_vm._v(_vm._s(_vm.$t('profile.friends.invite-friend-msg')))]),_c('v-dialog',{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary"}},on),[_c('v-icon',{staticClass:"me-2"},[_vm._v("fadl fa fa-qrcode")]),_vm._v(" "+_vm._s(_vm.$t('shared.show-qr-code'))+" ")],1)]}}],null,false,421053375)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('shared.qr-code')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('profile.friends.share-msg')))]),_c('QrCode',{attrs:{"data":_vm.befriendUrl}})],1)],1),_c('v-btn',{staticClass:"ms-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$helpers.copyToClipboard(_vm.befriendUrl, _vm.$toast)}}},[_vm._v(_vm._s(_vm.$t('shared.copy-link')))])],1):_vm._e(),(_vm.requested && _vm.requested.length)?_c('div',[_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('profile.friends.open-requests')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('profile.friends.open-requests-msg')))]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.requested,"item-key":"id","sort-by":"created","items-per-page":50,"show-group-by":false},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.friend.name))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.requested_by_id == _vm.profile.id)?_c('span',[_vm._v(_vm._s(_vm.$t('profile.friends.waiting-for-response')))]):_c('span',[_c('v-btn',{attrs:{"text":"","color":"green"},on:{"click":function($event){return _vm.acceptFriendRequest(item)}}},[_vm._v(_vm._s(_vm.$t('profile.friends.accept')))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.ignoreFriendRequest(item)}}},[_vm._v(_vm._s(_vm.$t('profile.friends.ignore')))])],1)]}}],null,false,2014558060)})],1):_vm._e(),_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('profile.friends.title')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('profile.friends.message')))]),(_vm.accepted)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.accepted,"item-key":"id","sort-by":"created","items-per-page":50,"show-group-by":false},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.openFriendDialog(item)}}},[_vm._v(_vm._s(item.friend.name))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.challengeFriend(item)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("fa-trophy")]),_vm._v(" "+_vm._s(_vm.$t('profile.friends.challenge')))],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteFriend(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("fa-unlink")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('profile.friends.unfriend')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.blockFriend(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("fa-ban")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('profile.friends.block')))])],1)],1)],1)]}}],null,false,1587878074)}):_vm._e(),(_vm.challenges && _vm.challenges.length)?_c('div',[_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('profile.friends.challenges')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('profile.friends.challenges-msg')))]),_c('EventGrid',{staticClass:"mx-4 mb-4",attrs:{"routeResolver":_vm.resolveViewChallengeRoute,"items":_vm.challenges,"minimal":true}})],1):_vm._e(),(_vm.blocked && _vm.blocked.length)?_c('div',[_c('v-card-subtitle',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('profile.friends.blocked-users')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('profile.friends.blocked-users-msg')))]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.blocked,"item-key":"id","sort-by":"created","items-per-page":50,"show-group-by":false},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.friend.name))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localDate")(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.deleteFriend(item)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"small":""}},[_vm._v("fa-unlink")]),_vm._v(" "+_vm._s(_vm.$t('shared.delete')))],1)]}}],null,false,151419882)})],1):_vm._e(),_c('FriendDialog',{ref:"friendDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }